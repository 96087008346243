@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Poppins:wght@100;300;400&family=Rubik&display=swap");
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}

input:valid {
  background-color: #fff;
}

.react-datepicker__input-container input {
  width: 100%;
}

.notAllowed {
  height: 80vh;
  margin: auto;
  display: grid;
  place-items: center;
  align-content: center;
}
.notAllowed img {
  width: 35%;
}
.notAllowed h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: 800;
  color: red;
}

.notAllowed a {
  text-decoration: none;
  padding: 1rem 3rem;
  background: #67bd67;
  border-radius: 20px;
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 800;
}

.notAllowed a:hover {
  background: #19795f;
}
