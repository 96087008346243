.dashboard {
  font-family: "Rubik", sans-serif;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h2 {
      @media only screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &_user {
      display: flex;
      align-items: center;

      &_avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1d1d1f;
        color: #fff;
        height: 2.5rem;
        width: 2.5rem;
        margin: 0 0.7rem;
        border-radius: 5px;
      }

      &_title {
        font-size: 1rem;
      }
    }
  }
}
