.layout {
  &_greenBg {
    width: 100%;
    height: 1rem;
    background-image: linear-gradient(to right, #6dd86d, #499c49);
    z-index: 1;
    position: fixed;
  }

  &_container {
    display: flex;
    align-items: center;
  }

  &_main {
    width: calc(100% - 100px);
    padding: 2.5rem 1rem;
    margin-left: 120px;
  }

  &_mainOpen {
    transition-property: all;
    transition-duration: 0.5s;
    max-width: calc(100% - 360px);
    margin-left: 360px;
    padding: 1.9rem;
  }
}
