.sidebar {
  background: #e9e9e9;
  padding: 1rem;
  position: fixed;
  top: 1rem;
  left: 0;
  height: 100%;
  overflow-y: auto;
  z-index: 0;

  width: 100px;
  transition-property: translate, opacity, visibility, width;
  transition-duration: 0.5s;

  &_header {
    display: flex;
    align-items: center;
    height: 72px;

    & img {
      cursor: pointer;
    }

    & button {
      background: none;
      border: none;
      margin: 1rem;
    }

    &_logo {
      width: 10rem;
      margin: 0 0.5rem;
    }
  }

  &_smallItem {
    width: 100%;
    display: grid;
    place-items: center;

    cursor: pointer;
    & img {
      width: 26px;
    }
  }

  &_itemGroup {
    margin: 2.2rem 3rem;
    font-size: 1.1rem;
    line-height: 2;
    display: flex;
    flex-direction: column;

    &_title {
      color: #a9a9a9;
      font-family: "Rubik", sans-serif;
      text-transform: uppercase;
      font-size: 1rem;
      margin-left: 1.4rem;
    }

    &_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      margin-left: -1rem;
    }

    &_itemActive {
      color: #3b913b;
      font-weight: bold;
    }

    & img {
      width: 25px;
      margin: 1rem 1rem 1rem 0;
    }
  }

  &_logout {
    cursor: pointer;
    margin: -1rem 1rem 1rem;
    margin-left: -1rem;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #e7e7e7;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cccc;
    border-radius: 50px;
  }
}

.sidebarOpen {
  opacity: 1;
  visibility: visible;
  translate: 0 0;
  width: 360px;
}

.burgerBtn {
  position: absolute;
  top: 1.8rem;
  padding: 1rem;
  background: none;
  border: none;
  margin: 1rem;
  cursor: pointer;
  & img {
    cursor: pointer;
  }
}
